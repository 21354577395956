import { toast } from 'sonner'

interface RetryConfig {
  resourceName: string
  maxRetries?: number
}

export const useRetryHandler = ({ resourceName, maxRetries = 1 }: RetryConfig) => {
  const handleRetry = (failureCount: number, error: any) => {
    // Check if the max retries limit has been reached
    if (failureCount >= maxRetries) {
      return false
    }

    // Extract status code from the error response
    const status = error?.response?.status

    // Don't retry for these specific status codes
    if ([401, 403, 404, 500].includes(status)) {
      switch (status) {
        case 401:
          console.error(`Nie jesteś zalogowany: ${resourceName}`)
          break
        case 403:
          console.error(`Brak uprawnień do wykonania tej operacji: ${resourceName}`)
          break
        case 404:
          console.error(`Nie znaleziono zasobu: ${resourceName}`)
          break
        case 500:
          console.error(`Wewnętrzny błąd serwera podczas pobierania danych: ${resourceName}`)
          break
      }
      return false
    }

    // For any other error, show a generic error message
    if (error instanceof Error) {
      console.error(`Wystąpił błąd: ${error.message}`)
    }

    // Retry if the error is not among the excluded status codes and retries are available
    return failureCount < maxRetries
  }

  return handleRetry
}
